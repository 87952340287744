<template>
    <div v-if="visitDetail !== null" class="mt-4">
    <div class="card top_primary_card mb-4  bg-primary font-bold text-white p-2 rounded-xl relative w-full">
      <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      <div class="flex justify-center items-center ">
        <h1 class="heading-2 text-center">Visit {{visitDetail.visitNumber}}/{{visitDetail.totalVisits}} for Job #{{visitDetail.jobNumber}}</h1>
      </div>
    </div>
    <div class="text-text1  card rounded-xl bg-white mb-4 p-4" v-if="visitDetail.isCompleted && visitDetail.invoiceId === 0 && visitItems.length === 0">
      <div class="relative">
          <p class=" heading-3 font-bold mb-2 text-error">Action Required</p>
          <p class="text-gray4 whitespace-pre-line">This visit is completed and has line items that are not invoiced, please create invoice or delete line items</p>
        </div>
      </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="  font-bold mb-2">Schedule</p>
              <p v-if="!allDay" class="text-gray4">{{visitDetail.visitStartDateTime | logTimeShow}} - {{visitDetail.visitEndDateTime | leaveTimeFilterForActivity}}</p>
              <p v-else class="text-gray4">{{visitDetail.visitStartDateTime | allDayTimeFill}}: Full-Day</p>
              <p class="text-success font-bold mt-2" v-if="visitDetail.isCompleted">Visit Completed</p>
            </div>
          </div>
          <div class=" card rounded-xl bg-white mb-4 p-4">
            <div class="pb-2" @click.stop="jobDetail()"><span class="cursor-pointer text-text1 hover:text-primary font-bold heading-4">{{visitDetail.jobTitle}}</span></div>
            <span class="cursor-pointer text-primary" @click.stop="customerDetail()">{{visitDetail.companyName}}</span>
            <div v-if="visitDetail.customerContactNumber !== ''" class=" flex justify-between">
              <span>{{visitDetail.customerContactNumber | phone}}</span>
              <div class="flex gap-4">
                <a class="text-primary" :href="`tel:${visitDetail.customerContactNumber}`"><i class="fa-solid fa-phone-volume"></i></a>
                <a class="text-primary" :href="`sms:${visitDetail.customerContactNumber}`"><i class="fa-solid fa-comment"></i></a>
              </div>
            </div>
          </div>
          <!-- <div class="">
            <div class=" font-bold heading-4">
              {{visitDetail.jobTitle}}
            </div>
          </div> -->
          <div v-if="visitDetail.latitude !== 0 && visitDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center mb-2">
                  <p class="font-bold">Address</p>
              </div>
              <div class="">
                  <div class="mb-4 flex whitespace-nowrap items-start" >
                    <div>
                      <span @click="openMap(visitDetail.latitude, visitDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">
                        <span v-if="visitDetail.fullAddress !== ''">{{visitDetail.fullAddress}}</span>
                        <span v-else>{{visitDetail.addressLine1}}</span>
                        </p>
                        <p  class="text-text1 heading-6">
                          <span v-if="visitDetail.houseNo !== ''">#: {{visitDetail.houseNo}}</span>
                          <span v-if="visitDetail.houseNo !== ''"> (Floor: {{visitDetail.floorNumber}})</span>
                        </p>
                        <p v-if="visitDetail.buildingName!== ''" class="text-text1 whitespace-pre-wrap">Bldg: {{visitDetail.buildingName}}</p>
                        <p v-if="visitDetail.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{visitDetail.directionNote}}</p>
                        <p v-if="visitDetail.contactName!== ''" class="text-text1 whitespace-pre-wrap">Contact: {{visitDetail.contactName}}</p>
                        <p v-if="visitDetail.contactNumber!== ''" class="text-text1 whitespace-pre-wrap">Phone #: {{visitDetail.contactNumber | phone}}</p>
                    </div>
                  </div>
              </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="  font-bold mb-2">Assign member(s)</p>
              <div class=" flex gap-2 flex-wrap">
                <div class="mb-4 flex flex-wrap cursor-pointer
                " v-for="(user, index) in visitDetail.assignToList" :key="index">
                  <Chip :class="`${user.jobStartTime !== '' ? ' font-semibold' : ''}`" :textColor="`${user.jobStartTime !== '' ? 'primary' : ''}`" :iconClass="`${user.jobStartTime !== '' ? 'fa-solid fa-circle-play' : ''}`" :chipText="`${user.firstName}  ${user.lastName}`"/>
                </div> 
              </div>
              <div class="flex justify-between sm:grid grid-cols-2 text-primary font-bold">
                <div><span @click="isShowTimeLog = true" class=" cursor-pointer">Time Log</span></div>
                <div><span @click="isLocationView = true" class=" cursor-pointer">Location</span></div>
              </div>
            </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p v-if="visitDetail.jobDescription !== ''" class="  font-bold mb-2">Instructions</p>
              <p v-else class="  font-bold">No Instructions</p>
              <p class="text-gray4 whitespace-pre-line">{{visitDetail.jobDescription}}</p>
            </div>
          </div>
        </div>
        <div class=" col-span-2">
          <div class="card p-4 bg-white rounded-xl mb-4" v-if="!visitDetail.isLead">
            <LineItems :visitDetail="{
              customerId:visitDetail.customerId,
              jobId:visitDetail.jobId,
              visitId:visitDetail.visitId,
              jobTitle: visitDetail.jobTitle,
              visitNumber: visitDetail.visitNumber,
              visitNumber: visitDetail.visitNumber,
              requestType: 'visit',
              }" />
          </div>
          <!-- <div class="card p-4 bg-white rounded-xl mb-4">
            <div class="flex my-2 justify-between items-center text-text1">
              <p class=" font-bold">Check Items ({{ visitCheckList.length }})</p>
              <div class="heading-5" v-if="!visitDetail.isCompleted">
                <span  @click="selectInvItems" class="text-primary font-bold cursor-pointer">+ Add Check</span>
              </div>
            </div>
            <div v-for="(checks, index) in visitCheckList" :key="index">
              <div class=" py-1 flex items-center">
                <li class="text-gray4">{{checks.checkListTitle}}</li>
                <span class="text-success pl-4" v-if="checks.isItemChecked"><i class="fas fa-check"></i></span>
                <p class="text-error pl-4" v-if="!checks.isItemChecked"><i class="fas fa-close"></i></p>
              </div>
            </div>
            <div class="mt-2 py-2 heading-5">
                <span v-if="sendObject.itemList.length === 0" @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add Item</span>
                <span v-else  @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add more Item</span>
              <p v-if="errObj.itemErr !== ''" class="text-error heading-7">{{errObj.itemErr}}</p>
            </div>
          </div> -->
          <div v-if="visitDetail !== null" class="card rounded-xl bg-white mb-4 p-4">
            <NotesList :detailId="parseInt(this.$route.params.visitId)" :requestType="'visit'" :noteTitle="'Visit Note'" />
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="showListing">
      <div class="custom_dialog rounded-xl" style="width: 400px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Check List
              </div>
            </div>
            <div class="p-2">
              <div v-for="(item, i) in showItemListing" :key="i + 'i'" class="card rounded-xl bg-white mb-2 p-3">
                <div class="flex items-center ">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(item)" v-model="item.isSelected" >
                  <span class="pl-3 text-text2 heading-5 cursor-pointer" @click="checkboxCheck(item)">{{item.checkListTitle}}</span>
                </div>
              </div>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="nextStep(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Next'" @buttonAction="nextStep(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="showinvoiceError">
      <div class="custom_dialog rounded-xl" style="width: 500px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white">
          <div>
            <div class="p-4">
              <p class="heading-4 text-text2">No invoice found in this visit, please add line items and convert to invoice if it is a billable visit</p>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="nextStep2(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ignore and Countinue'" @buttonAction="nextStep2(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimeLog :visitDetail="visitDetail" v-if="isShowTimeLog" />
    <FullScreeenLocation :visitDetail="visitDetail" v-if="isLocationView" />
   </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import Chip from '@/View/components/chip.vue';
import Button from '@/View/components/globalButton.vue'
import LineItems from '@/View/Admin/customer/jobs/LineItems.vue';
import NotesList from '@/View/Admin/customer/jobs/NotesList.vue';
import moment from 'moment'
import TimeLog from './TimeLog.vue';
import FullScreeenLocation from './FullScreeenLocation.vue';
var checkTimeInterval = null
export default {
  name: "lead",
  components: {
    Chip,
    Button,
    LineItems,
    NotesList,
    TimeLog,
    FullScreeenLocation,
  },
  data() {
    return {
      showinvoiceError: false,
      // isBtnDisable: true,
      showListing: false,
      showItemListing: [],
      itemSelect: false,
      visitItems: [],
      selectedMemberData: null,
      isAdminStarstop: false,
      isLocationView: false,
      isShowTimeLog: false,
      isCompleteVisit: false,
      allDay: false,
      updateSchedule: false,
      showAddUpdateTeam: false,
      isDeleteVisit: false,
      visitDetail: null,
      userStartTime: null,
      startTimeCounter: '',
    };
  },
  props: ["customerId"],
  mounted() {
    document.title = 'Visit Detail'
    this.getVisitInfo()
     this.$root.$on('getVisitItemsList', (data) => {
       this.visitItems = data
       console.log('this.visitItems', this.visitItems)
      document.body.style = 'overflow: visible;'
    })
     this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.isDeleteVisit) {
        this.deleteVisit()
      }
      document.body.style = 'overflow: visible;'
      this.isDeleteVisit = false
    })
    this.$root.$on('addUpdateTeamHandler', (data) => {
      if (data && this.showAddUpdateTeam) {
       this.getVisitInfo()
      }
      this.showAddUpdateTeam = false
    })
    this.$root.$on('sheduleUpdateHandler', (data) => {
      if (data && this.updateSchedule) {
       this.getVisitInfo()
      }
      this.updateSchedule = false
    })
    this.$root.$on('completeVisitHandler', (data) => {
      document.body.style = 'overflow: visible;'
      if (data && this.isCompleteVisit) {
       this.visitDetail = null
       this.getVisitInfo()
       this.getCheckList()
      }
      this.isCompleteVisit = false
    })
    this.$root.$on('visitTimeLogHandler', () => {
      this.isShowTimeLog = false
    })
    this.$root.$on('locationViewHandler', () => {
      this.isLocationView = false
    })
    this.$root.$on('adminVisitStartStopHandler', (response) => {
      if (response && this.isAdminStarstop) {
        this.getVisitInfo()
      }
      this.isAdminStarstop = false
    })
  },
    beforeDestroy () {
      this.$root.$off("locationViewHandler");
      this.$root.$off("visitTimeLogHandler");
      this.$root.$off("completeVisitHandler");
      this.$root.$off("confirmBoxHandler");
      this.$root.$off("getVisitItemsList");
      this.$root.$off("addUpdateTeamHandler");
      this.$root.$off("sheduleUpdateHandler");
    clearInterval(checkTimeInterval)
    },
  methods: {
    checkboxCheck (data) {
      data.isSelected = !data.isSelected
      // let count = 0
      // for (let index = 0; index < this.showItemListing.length; index++) {
      //   if (this.showItemListing[index].isSelected) {
      //     count += 1
      //   }
      // }
      // console.log('count', count)
      // console.log('this.showItemListing.length', this.showItemListing.length)
      // if (count === this.showItemListing.length) {
      //   this.isBtnDisable = false
      // } else {
      //   this.isBtnDisable = true
      // }
    },
    nextStep2 (data) {
      this.showListing = false
      this.showinvoiceError = false
      if (data) {
        this.isCompleteVisit = true
      } else {
        document.body.style = 'overflow: visible;'
      }
    },
    nextStep (data) {
      if (data) {
        if (this.visitDetail.invoiceId === 0 && this.visitItems.length === 0) {
          this.showinvoiceError = true
        } else {
          this.isCompleteVisit = true
        }
      } else {
        document.body.style = 'overflow: visible;'
      }
      this.showListing = false
    },
    jobDetail () {
      this.$router.push({name: 'CustomerJobDetail', params: {orgId: parseInt(this.$route.params.orgId), jobId: this.visitDetail.jobId}})
    },
    customerDetail () {
      this.$router.push({name: 'CustomersDetail', params: {customerId: this.visitDetail.customerId}})
    },
    startVisit (startValue) {
      let value = new Date(startValue) 
      let self = this
      checkTimeInterval = setInterval(function () {
        var now = new Date().getTime()
        var distance = now - value 
        var hr = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let hours = hr === 0 ? '00' : hr <= 9 ? '0' + hr : hr
        var min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let minutes = min === 0 ? '00' : min <= 9 ? '0' + min : min
        var sec = Math.floor((distance % (1000 * 60)) / 1000)
        let seconds = sec === 0 ? '00' : sec <= 9 ? '0' + sec : sec
        self.startTimeCounter = hours + ':' + minutes + ':' + seconds
      }, 1000)
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    getVisitInfo () {
      this.visitDetail = null
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.getVisitDetail(
        parseInt(this.$route.params.orgId),
        parseInt(this.$route.params.visitId),
          response => {
            this.visitDetail = response.Data
            var startTime = moment(response.Data.visitStartDateTime)
            var endTime = moment(response.Data.visitEndDateTime)
            var hoursDiff = endTime.diff(startTime, 'hours')
            var minutesDiff = endTime.diff(startTime, 'minutes') % 60
            console.log('hoursDiff', hoursDiff)
            console.log('hoursDiff minutesDiff', minutesDiff)
            if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
              this.allDay = true
            } else {
              this.allDay = false
            }
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  }
};
</script>
<style scoped>
</style>