<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative ">
        <div class="">
          <div class="bg-primary flex justify-between p-3 rounded-t-xl ">
            <div class=" font-medium text-white heading-3">
                Time Log
            </div>
          </div>
          <div class="p-4 bg-gray1 text-text1">
            <div class="flex mb-4">
              <div class="" v-for="(user, index) in userList" :key="index" >
                <StatusBtn class=" whitespace-nowrap" @buttonAction="selectUsers(index)" :selected="user.selected" :btnText="user.fullname" :lastInlist="userList.length === index + 1"/>     
              </div>
            </div>
            <div class="mb-4 p-4 card bg-white rounded-xl">
              <div v-if="totalHrTime > 0 || totalMinTime > 0">
                <span v-if="totalHrTime <= 9  && totalHrTime > 0">0{{totalHrTime}}</span>
                <span v-else-if="totalHrTime > 0">{{totalHrTime}}</span>
                <span v-if="totalHrTime > 0"> hr</span>
                <span v-if="totalMinTime > 0 && totalHrTime > 0">,</span>
                <span v-if="totalMinTime <= 9">0{{totalMinTime}}</span>
                <span v-else> {{totalMinTime}}</span>
                <span v-if="totalMinTime > 0"> Mins</span>
                <span> total logged</span>
              </div>
              <div v-else>
                No logged time
              </div>
            </div>
            <div class="">
              <div v-for="(user, index) in logTimeList" :key="index" class="mb-4 card bg-white rounded-xl">
                <div @click="user.showLogs = !user.showLogs" :class="`p-4 rounded-t-xl flex justify-between hover:text-primary cursor-pointer hover:bg-info ${user.showLogs ? 'text-primary bg-info' : ''}`">
                  <div>
                    <span>{{user.firstName}}</span> <span>{{user.lastName}}</span>
                    <span class=" ml-2 text-primary heading-6" v-if="user.working"><i class="fa-solid fa-circle-play"></i></span>
                  </div>
                  <div>
                    <div v-if="user.hr > 0 || user.min > 0">
                      <span v-if="user.hr <= 9 && user.hr > 0">0{{user.hr}}</span>
                      <span v-else-if="user.hr > 0">{{user.hr}}</span>
                      <span v-if="user.hr > 0"> hr</span>
                      <span v-if="user.min > 0 && user.hr > 0">,</span>
                      <span v-if="user.min > 0 && user.min <= 9">0{{user.min}}</span>
                      <span v-else-if="user.min > 0"> {{user.min}}</span>
                      <span v-if="user.min > 0"> Mins</span>
                    </div>
                  </div>
                </div>
                <div v-if="user.showLogs">
                  <div class="p-4 border-t heading-6" v-for="(log, index) in user.loghrList" :key="index">
                    <div class="flex justify-between">
                      <div>
                        {{log.startTime | logTimeShow}} - {{log.endTime | logTimeShow}}
                      </div>
                      <div>
                        <div v-if="log.working">Working</div>
                        <div v-else-if="log.hr > 0 || log.min > 0">
                          <span v-if="log.hr <= 9 && log.hr > 0">0{{log.hr}}</span>
                          <span v-else-if="log.hr > 0">{{log.hr}}</span>
                          <span v-if="log.hr > 0"> hr</span>
                          <span v-if="log.min > 0 && log.hr > 0">,</span>
                       
                          <span v-if="log.min > 0 && log.min <= 9">0{{log.min}}</span>
                          <span v-else-if="log.min > 0"> {{log.min}}</span>
                          <span v-if="log.min > 0"> Mins</span>
                        </div>
                        <div class="pr-12" v-else>-</div>
                      </div>
                    </div>
                    <div class="mt-4 flex gap-4" v-if="log.selfieList !== null" >
                      <div  v-for="(self, ind) in log.selfieList" :key="ind">
                        <div v-if="ind === 0" @click="showFullSelfi(self,log.startTime)" class=" card rounded-xl border-t border-gray-100 flex justify-center items-center bg-white attachment-card cursor-pointer" style="width: 100px; height: 80px">
                    
                        <div class="text-center">
                          <img style="height: 60px;width: 120px;object-fit: contain;" :src="self.selfiePath" class="scroll-img-view">
                          </div>
                        </div>
                        <div v-if="ind === 1" @click="showFullSelfi(self,log.endTime)" class=" card rounded-xl border-t border-gray-100 flex justify-center items-center bg-white attachment-card cursor-pointer" style="width: 120px; height: 80px">
                    
                        <div class="text-center">
                          <img style="height: 60px;width: 120px;object-fit: contain;" :src="self.selfiePath" class="scroll-img-view">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-gray1 w-full rounded-b-xl flex justify-end pr-3">
            <div class="text-rigth flex gap-2 pt-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'info'" :btnText="'Close'" @buttonAction="discard()"/>
            </div>
          </div>
        </div>
    </div>
    <div v-if="showFullSelfiImage" class="popup_overlay">
    <div class="custom_dialog" :style="`width: 100%; overflow: auto; height:100vh;`">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-bold text-white heading-3">
                Selfie
            </div>
             <div class="flex items-center">
              <p @click="closeSelfiImage" class="cursor-pointer text-white heading-1 pl-4 font-bold">
                <i class="fas fa-times"></i>
              </p>
            </div>
          </div>
          <div class="flex justify-center items-center pt-12">
            <img :style="`height: 100%;width: auto;object-fit: contain; max-height: ${imageDivHeight}px;`" :src="fulSelfiData.selfiePath" class="scroll-img-view">
          </div>
          <div class=" text-center mt-4">
            {{selfiTime | logTimeShow}}
          </div>
        </div>
        </div>
    </div>
  </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import moment from 'moment'
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
  name: 'sendEmail',
  components: {
    Button,
    StatusBtn,
  },
  data () {
    return {
        totalHrTime: 0,
        totalMinTime: 0,
        totalSpendTime: 0,
        pathPrefix: '',
        noteLIst: [],
        userList: [],
        logTimeList: [],
        listSelectedUser: [],
        showFullSelfiImage: false,
        isDeleteSelfi: false,
        fulSelfiData: null,
        selfiTime: '',
        imageDivHeight: window.innerHeight - 150
    }
  },
  props: ["visitDetail"],
   created() {
  },
  mounted () {
    document.title = 'Timelog List'
    document.body.style = 'overflow: hidden;'
    this.logTimeGet()
    // this.jobTimeLogUsersGet()
  },
  beforeDestroy () {
    // this.$root.$off("confirmBoxHandler");
  },
  watch: {},
  methods: {
    showFullSelfi (data,time) {
      this.fulSelfiData = data
      this.selfiTime = time
      this.showFullSelfiImage = true
    },
    closeSelfiImage () {
      this.fulSelfiData = null
      this.selfiTime = ''
      this.showFullSelfiImage = false
    },
    selectUsers (index) {
      this.listSelectedUser = []
      this.userList[index].selected = !this.userList[index].selected
      let count = 0
      this.userList.map(data => {
        if (data.selected) {
          count += 1
          this.listSelectedUser.push(data.userDetailId)
        }
      })
      if (count === 0) {
        this.userList.forEach(e => {
          if (e.userDetailId !== this.userList[index].userDetailId) {
            e.selected = true
          this.listSelectedUser.push(e.userDetailId)
          }
          
        })
      }
      this.logTimeGet()
    },
    logTimeGet () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.getLogedTIme(
        this.visitDetail.jobId,
        this.visitDetail.visitId,
        (response) => {
          let templist = []
          this.logTimeList = []
          this.totalSpendTime = 0
           templist = response.Data !== null ? response.Data : []
           templist.map((data) => {
            let obj = {
              firstName: data.firstName,
              lastName: data.lastName,
              min: 0,
              hr: 0,
              loghrList: [],
              showLogs: false,
              working: false,
            }
            let subTotaltime = 0
            if (data.logHoursList !== null) {
              data.logHoursList.map(log => {
                let hr = 0
                let min = 0
                if (log.startTime !== '' && log.endTime !== '' ) {
                  let startTime = moment(log.startTime)
                  let endTime = moment(log.endTime)
                  let hoursDiff = endTime.diff(startTime, 'hours')
                  let minutesDiff = endTime.diff(startTime, 'minutes') % 60
                  let totalMin = endTime.diff(startTime, 'minutes')
                  subTotaltime += totalMin
                  hr = hoursDiff
                  min = minutesDiff

                  let subObj = {
                  endTime: log.endTime,
                  jobTimeTrackingId: log.jobTimeTrackingId,
                  selfieList: log.selfieList,
                  startTime: log.startTime,
                  userDetailId: log.userDetailId,
                  hr: hr, 
                  min: min,
                  working: false
                }
                obj.loghrList.push(subObj)
                } else if (log.startTime !== '' && log.endTime === '') {
                  obj.working = true
                  let startTime = moment(log.startTime)
                  let endTime = moment(new Date())
                  let hoursDiff = endTime.diff(startTime, 'hours')
                  let minutesDiff = endTime.diff(startTime, 'minutes') % 60
                  let totalMin = endTime.diff(startTime, 'minutes')
                  subTotaltime += totalMin
                  hr = hoursDiff
                  min = minutesDiff

                  let subObj = {
                  endTime: log.endTime,
                  jobTimeTrackingId: log.jobTimeTrackingId,
                  selfieList: log.selfieList,
                  startTime: log.startTime,
                  userDetailId: log.userDetailId,
                  hr: hr, 
                  min: min, 
                  working: true
                }
                obj.loghrList.push(subObj)
                }
              })
            }
            obj.hr = Math.floor(subTotaltime / 60)
            obj.min = subTotaltime % 60
            this.totalSpendTime += subTotaltime
            this.logTimeList.push(obj)
           })
            this.totalHrTime = Math.floor(this.totalSpendTime / 60)
            this.totalMinTime = this.totalSpendTime % 60
            this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        })
    },
    jobTimeLogUsersGet () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.getJobTimeLogUsers(
        this.visitDetail.jobId,
        response => {
          let temp = []
            if (response.Data.tableRow !== null) {
                response.Data.tableRow.map(data => {
                    temp.push({...data, fullname: data.firstName + ' ' + data.lastName, selected: true})
                    this.listSelectedUser.push(data.userDetailId)
                })
            }
            this.userList = temp
             this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        })
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('visitTimeLogHandler', false)
    },
  },
}
</script>
<style scoped>

</style>