<template>
  <div class="text-text1">
    <div>
      <div class="flex my-2 justify-between items-center">
        <p class=" font-bold">Line Items</p>
      </div>
    <div  class="mt-2" v-if="selectedItemList.length > 0">
        <div class="px-4  cursor-pointer hover:bg-gray1 border-b py-1" v-for="(data, index) in selectedItemList" :key="index + 'key'">
        <div class="text-text2 text-sm flex mb-1 font-bold justify-between">
            <p class="pr-4" >{{data.itemName}}</p>
        </div>
        <div class="text-text2 flex">
            <p class="heading-6 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
        </div>
        <div class="text-text2 flex justify-between">
            <p class="heading-6">{{data.itemQty}} x {{data.itemPrice | amountOnlyNumber}}</p>
            <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
        </div>
        
        </div>
    </div>
    <div v-else>
      <NoRecords :alertMessage="`No records found.`" />
    </div>
    
    <div  class="" v-if="selectedItemList.length > 0">
    <div class="flex justify-between py-2 px-4">
        <p class="text-text2 font-bold w-full">Item Total</p>
        <p class="text-text2 font-bold w-full text-right">{{subTotal.toFixed(2) | amountOnlyNumber}}</p>
    </div>
    </div>  
    </div>
   </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '@/View/components/noRecords.vue'
// import moment from 'moment'
export default {
  name: "customer",
  components: {
    NoRecords,
  },
  mixins: [deboucneMixin],
  data() {
    return {
            subTotal: 0,
            itemSelect: false,
            itemSelectDetail: false,
            selectedItemForDetail: null,
            selectedItemList: [],
    };
  },
  created() {
  },
  computed: {
  },
  props: ['visitDetail'],
  mounted() {
    document.title = 'Line Items'
    this.getVisitItemList()
  },
  methods: {
    createInvoiceItem () {
      
      localStorage.setItem('visitDetail', JSON.stringify(this.visitDetail))
      this.$router.push({name: 'createInvoice', query: {visitId: this.visitDetail.visitId, parentDetaiId: this.visitDetail.customerId}})
        // this.$router.push({name: 'createInvoice', query: {visitId: parseInt(this.$route.params.visitId), parentDetaiId: this.visitDetail.customerId}})
    },
    getVisitItemList () {
      this.subTotal = 0
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.JobItemList(
          this.visitDetail.visitId,
          this.visitDetail.jobId,
          response => {
            this.selectedItemList = response.Data.tableRow !== null ? response.Data.tableRow : []
            if (this.visitDetail.requestType === 'job') {
              this.$root.$emit("getVisitItemsList", this.selectedItemList);
            }
            this.selectedItemList.map(data => {
              this.subTotal += data.itemAmount
            })
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  },
  beforeDestroy() {
    this.$root.$off("removeSelectDetailHandler");
    this.$root.$off("itemSelectionHandler");
    this.$root.$off("itemSelectDetailHandler");
  },
};
</script>
<style scoped>

</style>